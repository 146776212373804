export const catalogoDeOpciones = {
  "accessory": [
    "eyepatch",
    "glasses1",
    "glasses2",
    "glasses3",
    "glasses4",
    "glasses5",
    "sunglasses1",
    "sunglasses2",
    "<url>"
  ],
  "face": [
    "awe",
    "talking",
    "smile",
    "smile-big",
    "laughing",
    "smile-gap",
    "calm",
    "blank",
    "cheeky",
    "serious",
    "concerned-fear",
    "gasp",
    "peace",
    "cute",
    "cyclops",
    "fangry",
    "monster",
    "driven",
    "eating-happy",
    "eyes-closed",
    "fear",
    "hectic",
    "loving-grin1",
    "loving-grin2",
    "old",
    "rage",
    "sad",
    "suspicious",
    "tired",
    "angry",
    "<url>"
  ],
  "head": [
    "afro",
    "bangs1",
    "bangs2",
    "bantu-knots",
    "teddy",
    "bun1",
    "bun2",
    "buns",
    "cornrows1",
    "cornrows2",
    "flat-top1",
    "flat-top2",
    "gray-bun",
    "gray-medium",
    "gray-short",
    "beanie",
    "sierra",
    "hijab",
    "long-afro",
    "long-bangs",
    "long-curly",
    "long-straight",
    "medium1",
    "medium2",
    "medium3",
    "medium-bangs1",
    "medium-bangs2",
    "medium-bangs3",
    "medium-straight",
    "mohawk1",
    "mohawk2",
    "bald1",
    "bald2",
    "bald3",
    "pomp",
    "shaved1",
    "shaved2",
    "shaved3",
    "short1",
    "short2",
    "short3",
    "short4",
    "short5",
    "turban",
    "twists1",
    "twists2",
    "<url>"
  ],
  "facialHair": [
    "chinhair",
    "beard1",
    "beard2",
    "beard3",
    "beard4",
    "goatee1",
    "goatee2",
    "mustache1",
    "mustache2",
    "mustache3",
    "mustache4",
    "mustache5",
    "mustache6",
    "mustache7",
    "mustache8",
    "mustache9",
    "<url>"
  ],
  "body": [
    "blazer",
    "buttonup1",
    "buttonup2",
    "laptop",
    "striped-pocket-tee",
    "coffee",
    "phone",
    "dress",
    "explaining",
    "jacket",
    "gaming",
    "tank",
    "hoodie",
    "killer",
    "paper",
    "pointing-up",
    "polkadot-jacket",
    "polo-sweater",
    "shirt-coat",
    "sporty-tee",
    "striped-tee",
    "sweater-dots",
    "sweater-crossed",
    "tee1",
    "tee2",
    "tee-crossed",
    "tee-selena",
    "thunder-tee",
    "turtleneck",
    "shrug",
    "<url>"
  ]
}
