<script>
import { catalogoDeOpciones } from './catalogoDeOpciones.js'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      catalogoDeOpciones: catalogoDeOpciones,
      catalogoDeOpcionesComplemento: [
        { name: 'accessory', text: 'Accesorios', selected: '' },
        { name: 'face', text: 'Cara', selected: '' },
        { name: 'head', text: 'Pelo', selected: '' },
        { name: 'facialHair', text: 'Bello facial', selected: '' },
        { name: 'body', text: 'Cuerpo', selected: '' },
      ],
      selectedCatalogo: 'accessory',
      isCharacter: 1, // colors
      catalogoDeOpcionesColores: [
        { name: 'hat', text: 'Gorro', className: '--peep-hat-color', selected: '#fff' },
        { name: 'hair', text: 'Pelo', className: '--peep-hair-color', selected: '#fff' },
        { name: 'skin', text: 'Piel', className: '--peep-skin-color', selected: '#fff' },
        { name: 'accessory', text: 'Accesorio', className: '--peep-accessory-color', selected: '#fff' },
        { name: 'facialHair', text: 'Bello facial', className: '--peep-facial-hair-color', selected: '#fff' },
        { name: 'clothes', text: 'Ropa', className: '--peep-clothes-color', selected: '#fff' },
        { name: 'object', text: 'Objeto', className: '--peep-object-color', selected: '#fff' }
      ],
      selectedCatalogoColor: 'hat',
      showResult: true,
      loading: false
    }
  },
  computed: {
    resultCatalogo() {
      return this.catalogoDeOpcionesComplemento.map(item => item.selected).join(' ')
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    colors() {
      return {
        hat: this.catalogoDeOpcionesColores[0].selected,
        hair: this.catalogoDeOpcionesColores[1].selected,
        skin: this.catalogoDeOpcionesColores[2].selected,
        accessory: this.catalogoDeOpcionesColores[3].selected,
        facialHair: this.catalogoDeOpcionesColores[4].selected,
        clothes: this.catalogoDeOpcionesColores[5].selected,
        object: this.catalogoDeOpcionesColores[6].selected
      }
    }
  },
  mounted() {
    this.$store.getters.fetchGet({ path: `Persona/GetPersonaById/${this.userLoggedIn.id}` })
      .then(response => response.json())
      .then(result => {
        if (result.stringAvatar) {
          this.setAvatarFrom(result.stringAvatar)
        } else {
          this.randomize()
        }
      }).catch(() => {
        this.randomize()
      })



    this.$watch(() => this.catalogoDeOpcionesColores, () => {
      this.showResult = false
      this.$nextTick(() => {
        this.showResult = true
      })
    },
      { deep: true }
    )


  },
  methods: {
    setAvatarFrom(avatarString) {
      const objAvatar = JSON.parse(avatarString)
      const newObject = window.structuredClone(this.catalogoDeOpcionesComplemento)
      newObject.forEach(item => {
        item.selected = objAvatar[item.name]
      })
      this.catalogoDeOpcionesComplemento = newObject

      const newObjectColors = window.structuredClone(this.catalogoDeOpcionesColores)
      newObjectColors.forEach(item => {
        item.selected = objAvatar[item.className]
      })
      this.catalogoDeOpcionesColores = newObjectColors
    },
    handleClickedCatalogoItem(item, nameElement) {
      const newObject = window.structuredClone(this.catalogoDeOpcionesComplemento)
      const index = newObject.findIndex(element => element.name === nameElement)
      newObject[index].selected = item
      this.catalogoDeOpcionesComplemento = newObject
    },
    randomize() {
      // randomize
      const copyCatalogo = window.structuredClone(this.catalogoDeOpcionesComplemento)
      const namesCatalogo = copyCatalogo.map(item => item.name)

      namesCatalogo.forEach(name => {
        const randomIndex = Math.floor(Math.random() * this.catalogoDeOpciones[name].length)
        const randomItem = this.catalogoDeOpciones[name][randomIndex]
        const index = copyCatalogo.findIndex(item => item.name === name)
        copyCatalogo[index].selected = randomItem
      })
      this.catalogoDeOpcionesComplemento = copyCatalogo
    },
    handleClickedColor({ event, color }) {
      if (event) {
        color = event.target.value
      }

      const newObject = window.structuredClone(this.catalogoDeOpcionesColores)
      const index = newObject.findIndex(element => element.name === this.selectedCatalogoColor)
      newObject[index].selected = color
      this.catalogoDeOpcionesColores = newObject

    },
    async saveAvatar() {
      const dataClass = {
        ...this.catalogoDeOpcionesComplemento.reduce((acc, item) => {
          acc[item.name] = item.selected
          return acc
        }, {}),
        ...this.catalogoDeOpcionesColores.reduce((acc, item) => {
          acc[item.className] = item.selected
          return acc
        }, {})
      }

      const data = {
        StringAvatar: JSON.stringify(dataClass)
      }

      this.loading = true

      const response = await this.$store.getters.fetchPut({ path: `Persona/SetAvatar/${this.userLoggedIn.id}`, data })
      const result = await response.json()
      console.log(result);
      if (response.ok) {
        Swal.fire({
          title: 'Avatar guardado',
          text: 'Tu avatar ha sido guardado con éxito',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        localStorage.setItem('stringAvatar', JSON.stringify(dataClass))
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Ha ocurrido un error al guardar tu avatar',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }

      this.loading = false

    }
  }
}
</script>

<template>
  <div class="container-fluid">
    <div class="card fullpage">
      <header>
        <h3>Creación de avatar</h3>
        <ul class="header-sections">
          <li :class="{ active: isCharacter }" @click="isCharacter = 1">Catalogo</li>
          <li :class="{ active: !isCharacter }" @click="isCharacter = 0">Colores</li>
        </ul>
      </header>

      <section class="body-sections">
        <!-- 2 sub secciones -->
        <div class="first-section">
          <div class="characters" v-show="isCharacter">
            <div :class="{ active: name == selectedCatalogo }" class="character"
              v-for="{ name, text } in catalogoDeOpcionesComplemento" :key="name + 'section'"
              @click="selectedCatalogo = name">
              {{ text }}
            </div>
          </div>
          <div class="characters" v-show="!isCharacter">
            <div :class="{ active: name == selectedCatalogoColor }" class="character"
              v-for="{ name, text } in catalogoDeOpcionesColores" :key="name + 'section'"
              @click="selectedCatalogoColor = name">
              {{ text }}
            </div>
          </div>
          <div class="character btn-save" @click="saveAvatar" :loading="loading">
            <span v-show="!loading" style="color: #fff">Guardar</span>
            <span v-show="loading">
              <i class="fas fa-spinner fa-spin-pulse fa-1x text-dark"></i>
            </span>
          </div>
        </div>


        <!-- 2 catalogos -->
        <div class="catalogo" v-show="isCharacter">
          <div class="catalogo-item"
            :class="{ 'showHead': selectedCatalogo != 'body', 'isBody': selectedCatalogo == 'body' }"
            v-for="item in catalogoDeOpciones[selectedCatalogo]" :key="item"
            @click="() => handleClickedCatalogoItem(item, selectedCatalogo)"
            :data-css-peeps="item != '<url>' ? `${item} ${selectedCatalogo != 'head' ? catalogoDeOpcionesComplemento[2].selected : ''}` : ''">
          </div>
        </div>
        <div v-show="!isCharacter">

          <p class="mb-2">No todas las características del catalogo puede tener un color arbitrario</p>
          <input type="color" @change="($event) => handleClickedColor({ event: $event })">
        </div>

        <div class="wrapper-result">
          <div :data-css-peeps="resultCatalogo" class="miAvatar" v-if="showResult">

          </div>
        </div>
      </section>

      <!-- <div class="magic" data-css-peeps>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
h3 {
  text-align: center;
  padding: 0.25rem 0;
}

.first-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-sections {
  padding: 1rem;
  display: grid;
  grid-template-columns: 200px 1fr 1fr;
  gap: 1rem;
  height: 60vh;
}

.header-sections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;
  text-align: center;
}

.header-sections>* {
  border: solid 1px #285194;
  padding: .8rem;
  font-size: 1.2rem;
  cursor: pointer;
}

.header-sections>*.active {
  background-color: #285194;
  color: #fff;
}

.characters {
  display: grid;
}

.character {
  padding: .5rem 0;
  border: solid 1px #285194;
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.character.active {
  background-color: #285194;
  color: #fff;
}

.character.btn-save {
  background-color: #17C964;
  color: #fff;
}

.catalogo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, 120px);
  gap: 0.5rem;
  /* height: 500px; */
  overflow-y: scroll;
}

.catalogo-item {
  grid-column: span 1;
  grid-row: span 1;
  height: 120px;
  width: auto;
  --peep-zoom: 2;
  border: solid 1px #000;
  cursor: pointer;
}

.catalogo-item.active {
  background-color: rgba(255, 255, 255, 0.4);
}

.showHead {
  --peep-body-offset-x: 10px;
  --peep-body-offset-y: 40px;
}

.isBody {
  --peep-body-offset-x: 0px;
  --peep-body-offset-y: 0px;
  --peep-zoom: 1;

}

.wrapper-result {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid 1px #000; */
}

.miAvatar {
  /* border: solid 1px #000; */
  width: 320px;
  height: 320px;
}




* {
  --peep-hat-color: v-bind(colors.hat);
  --peep-hair-color: v-bind(colors.hair);
  --peep-skin-color: v-bind(colors.skin);
  --peep-accessory-color: v-bind(colors.accessory);
  --peep-facial-hair-color: v-bind(colors.facialHair);
  --peep-object-color: v-bind(colors.object);
  --peep-clothes-color: v-bind(colors.clothes);
  --peep-flipped: 100;
}
</style>